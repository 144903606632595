exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/AboutUs.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/Contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-past-trips-tsx": () => import("./../../../src/pages/PastTrips.tsx" /* webpackChunkName: "component---src-pages-past-trips-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/Privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/Register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/Terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-upcoming-trips-tsx": () => import("./../../../src/pages/UpcomingTrips.tsx" /* webpackChunkName: "component---src-pages-upcoming-trips-tsx" */)
}

